export const oneImage = '/smart-loan/apply/oneImage'; // 上传图片
export const brand = '/smart-loan/system/brand'; // 获取公司信息
export const getUserInfo = '/smart-loan/user/getUserInfo'; // 获取用户信息
export const queryStatus = '/smart-loan/apply/queryStatus'; // 获取上笔订单详情
export const getUserInfoDetail = '/smart-loan/user/getUserInfoDetail'; // 获取用户信息详情
export const userBankCard = '/smart-loan/user/bank/card'; // 获取用户银行卡信息
export const apply = '/smart-loan/user/apply/'; // 申请信息（拼接）
export const session = '/smart-loan/session'; // 登录
export const validationCode = '/smart-loan/app/validation/code'; // 获取验证码
export const password = '/smart-loan/modify/password'; // 修改密码
export const registeredUser = '/smart-loan/session/registeredUser'; // 注册
export const contractList = '/smart-loan/contract/contractList'; // 合同列表
export const contractDetail = '/smart-loan/contract/contractDetail'; // 合同详情
export const repayList = '/smart-loan/contract/repayList'; // 还款单

// export const applyPre = '/smart-loan/apply/pre'; // 预申请 1
// export const realnameAuth = '/smart-loan/apply/realnameAuth'; // 身份信息认证 2
// export const jobAuth = '/smart-loan/apply/jobAuth'; // 工作认证 3
// export const reationshipAuth = '/smart-loan/apply/reationshipAuth'; // 联系人认证 4
// export const commit = '/smart-loan/apply/commit'; // 身份认证 6
// export const sign = '/smart-loan/contract/sign'; // 签约 7

export const step = '/smart-loan/apply/step'; // 申请流程

export const product = '/smart-loan/product/getProduct'; // 获取产品
export const scheduleCalc = '/smart-loan/schedule/scheduleCalc'; // 获取试算
export const applyByCancel = '/smart-loan/apply/applyByCancel'; // 取消申请
export const getRecommendUrl = '/smart-loan/recommend/getRecommendUrl'; // 获取邀请链接
export const myRecommend = '/smart-loan/recommend/myRecommend'; // 我的推广
export const myCoupon = '/smart-loan/coupon/myCoupon'; // 我的优惠卷
export const useMyCoupon = '/smart-loan/coupon/useMyCoupon'; // 使用优惠卷
export const behavior = '/smart-loan/app/behavior'; // 用户行为
export const continuedLoan = '/smart-loan/apply/continuedLoan'; // 是否续贷
export const checkPhoneHasLoan = '/smart-loan/contract/checkPhoneHasLoan'; // 账号是否是续贷
export const bankAccount = '/smart-loan/pay/verify/bankAccount'; // 校验银行账户
export const getCustomer = '/smart-loan/customer/getCustomer'; // 客户信息
export const updInfo = '/smart-loan/customer/updInfo'; // 更新客户信息
export const kaptcha = '/smart-loan/apply/validation/kaptcha'; // 验证手机号验证码
export const getPayPlatAddress = '/smart-loan/pay/getPayPlatAddress'; // 获取还款地址
export const getRepayBankAccount = '/smart-loan/pay/getRepayBankAccount'; // 获取对公账号

// 字典
export const dictionary = '/smart-loan/dictionary/'; // 字典
export const EDUCATION = '/smart-loan/dictionary/EDUCATION'; // 学历
export const MARITAL_STATUS = '/smart-loan/dictionary/MARITAL_STATUS'; // 婚姻状况
export const DISTRICT = '/smart-loan/dictionary/DISTRICT'; // 地址
export const MONTHLY_INCOME = '/smart-loan/dictionary/MONTHLY_INCOME'; // 平均月收入
export const RELATIONSHIP = '/smart-loan/dictionary/RELATIONSHIP'; // 亲属联系人
export const OTHER_RELATIONSHIP = '/smart-loan/dictionary/OTHER_RELATIONSHIP'; // 其他联系人
export const BANK = '/smart-loan/dictionary/BANK'; // 获取银行
export const RELIGION = '/smart-loan/dictionary/RELIGION'; // 宗教
export const SALARY_TYPE = '/smart-loan/dictionary/SALARY_TYPE'; // 工资类型
