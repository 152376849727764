<template>
  <div class="home">
    <mt-header :title="$t('projectName')">
      <call-phone slot="left" />
      <i slot="right" class="iconfont titieIcon" @click="$router.push('/mine')">&#xe677;</i>
    </mt-header>
    <div class="loan">
      <div class="box">
        <img class="bg" src="../assets/loan_img_1.png">
        <div class="val">
          <div class="title">
            {{ title }}
          </div>
          <div class="tips" v-if="loanType === 1">
            ₦ {{ (queryStatus.apply||{}).repayAmounnt | formatMoney }}
            <div class="tips_date"> Repay date: {{(queryStatus.apply||{}).repayDate | formatDate('DD-MM-YYYY')}}</div>
          </div>
          <div class="tips" v-else-if="loanType === 2">
            ₦ {{ (queryStatus.apply||{}).applyAmount | formatMoney }}
          </div>
          <div class="tips" v-else>
            ₦ ????
          </div>
          <div class="bottom">
            <mt-button  v-if="loanType === 1" size="large" @click="submit">View Details</mt-button>
            <mt-button  v-if="loanType !== 1" size="large" @click="submit">{{$t("applyImmediately")}}</mt-button>
          </div>
        </div>
      </div>
      <div class="link">
        {{ $t('clickApplyAgree') }}
        <span @click="onServiceAgreement">{{ $t('serviceAgreement') }}</span>
      </div>
    </div>
    <div class="not_settle" v-if="user.notSettleCount">
      <div class="val">
        <div>
          {{ $t('loanLengthTips') }}
          <span>{{ user.notSettleCount }}</span>
        </div>
        <span class="more" @click="$router.push('/mine/repayRecord')">{{ $t('viewMore') }}</span>
      </div>
      <img src="../assets/loan_img_4.png">
    </div>
    <div class="flag">
      <img src="../assets/loan_img_3.png">
      <div class="val">
        <div>
          {{ $t('upTo') }}
          <span>₦ 600.000</span>
        </div>
        <span>{{ $t('loanInfo') }}</span>
      </div>
      <div class="go" @click="submit">
        GO>
      </div>
    </div>
    <div class="userRecomendation">
      {{ $t('userRecomendation') }}
      <svg aria-hidden="true" class="icon">
        <use xlink:href="#iconkuaisu_jiasu" />
      </svg>
    </div>
    <div class="inviteFriends">
      <div class="val">
        <span>{{ $t('inviteFriends') }}</span>
        <!-- <div class="btn" @click="$router.push('/mine/inviteFriends')">
          {{ $t('viewMore') }}
        </div> -->
      </div>
      <img src="../assets/loan_img_2.png">
    </div>
    <div class="box">
      <!-- <div class="step">
        <div class="title">{{ $t('homeStep1.title') }}</div>
        <div v-for="(item, index) in $t('homeStep1.list')" :key="index" class="item">
          <div class="index">{{ index + 1 }}</div>
          <div class="des">
            <div>{{ item.title }}</div>
            <div>{{ item.value }}</div>
          </div>
          <svg aria-hidden="true" class="icon">
            <use :xlink:href="`#${iconList[0][index]}`" />
          </svg>
        </div>
      </div> -->
      <!-- <div class="step">
        <div class="title">{{ $t('homeStep2.title') }}</div>
        <div v-for="(item, index) in $t('homeStep2.list')" :key="index" class="item">
          <svg aria-hidden="true" class="icon">
            <use :xlink:href="`#${iconList[1][index]}`" />
          </svg>
          <div class="des">
            <div>{{ item.title }}</div>
            <div>{{ item.value }}</div>
          </div>
        </div>
      </div> -->
      <!-- <div class="step">
        <div class="title">{{ $t('homeStep3.title') }}</div>
        <div v-for="(item, index) in $t('homeStep3.list')" :key="index" class="item">
          <svg aria-hidden="true" class="icon">
            <use :xlink:href="`#${iconList[2][index]}`" />
          </svg>
          <div class="des">
            <div>{{ item.title }}</div>
            <div>{{ item.value }}</div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { getUserInfo, queryStatus, applyByCancel, checkPhoneHasLoan } from '../utils/api'
import callPhone from '@/components/callPhone.vue'
import { step } from '../utils/api'
import getLocation from '../utils/gps'
import branch from '../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../utils/gtm.js'
export default {
  name: 'Home',
  components: {
    callPhone,
  },
  data() {
    return {
      loanType: 0,
      iconList: [
        [
          'iconcaozuoguocheng',
          'icon_shijian_xiaoshuai',
          'iconiconset02100'
        ], [
          'iconnationaarea',
          'iconnianling',
          'icongongzuo'
        ], [
          'iconjurassic_pass-others',
          'iconicon-shortcut',
          'iconkafei',
          'iconkuaisu_jiasu'
        ]
      ]
    }
  },
  computed: {
    ...mapState(["brand", "user", "queryStatus"]),
    title() {
      if (this.loanType === 1) {
        return this.$t('userRepayInfo')
      } else if (this.loanType === 2) {
        return this.$t('userRenewalInfo')
      } else {
        return this.$t('userBorrowInfo')
      }
    },
  },
  watch: {
    'user.applyStatus': {
      handler(val) {
        switch (val) {
          case 'EMPTY': // 无申请单
          case 'APPLY': // 申请中
          case 'SIGN':
          case 'APPROVE':
          case 'LOAN':
            this.loanType = 0;
            return;
          case 'REPAY':
            this.loanType = 1;
            return;
          default:
            this.loanType = 2;
            break;
        }
        this.$axios({
          method: "post",
          url: checkPhoneHasLoan,
          data: {
            phone: this.user.phone,
          }
        })
          .then(async (e) => {
            if (e.status.code == "000") {
              this.loanType = e.body? 2: 0;
            } else {
              this.$toast(e.status.msg);
            }
          })
          .catch(() => {});
      },
      immediate: true,
    }
  },
  created() {
    gtmPageView(this)
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P00_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P00_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER", "SET_QUERY_STATUS"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await Promise.all([
        this.getUser(),
        this.getQueryStatus(),
      ])
      this.$indicator.close();
    },
    submit() {
      switch (this.user.applyStatus) {
        case 'APPROVE': // 申请中
        case 'SUPPLEMENTARY': // 非风险类拒绝
          // 订单详情
          this.$router.push({path: '/apply/done'})
          break
        case 'LOAN': // 待放款、放款处理中、放款成功
        case 'REPAY': // 还款中
          // 还款 到借款详情
          this.$router.push(`/apply/loanDetail?contractNo=${this.user.applyId}`)
          break
        default:
          if (this.user.continuedLoan === 'Y') {
            this.onReApply() // 续贷申请
            break
          }
          // this.onApplyPre() // 首贷申请
          break
      }
    },
    // 预申请
    async onApplyPre() {
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('01_FIRST_LOAN_APPLY', { content_name: '01_FIRST_LOAN_APPLY' })
        }
        // Google Analytics  FIRST_LOAN_APPLY
        if (localStorage['ga']) {
          window.gtag('event', 'FIRST_LOAN_APPLY')
        }
      } catch(e) {
        console.log(e)
      }
      if (this.queryStatus.apply && this.queryStatus.apply.currentStep) {
        switch (this.queryStatus.apply.currentStep) {
          case 1:
            this.$router.push('/step/realnameauth')
            return;
          case 2:
            this.$router.push('/step/jobauth')
            return;
          case 3:
            this.$router.push('/step/relationshipauth')
            return;
          case 4:
            this.$router.push('/step/bankInfo')
            return;
          case 5:
            this.$router.push('/step/idcardauth')
            return;
          case 6:
            this.$router.push('/step/confirm')
            return;
          default:
            break;
        }
      }
      await this.$axios({
        method: "post",
        url: step,
        data: {
          deviceId: 'H5',
          phone: this.user.phone,
          idcard: this.user.idcard,
          gps: await getLocation(),
          currentStep: 1,
          totalSteps: 7,
        },
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              ...e.body.session.user,
              applyId: e.body.applyId
            })
            this.$router.push('/step/realnameauth')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      branch.logEvent('01_FIRST_LOAN_APPLY', { metadata: this.user.phone })
    },
    // 续贷申请
    async onReApply() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('11_CONTINUED_LOAN_APPLY', {
            content_name: '11_CONTINUED_LOAN_APPLY'
          })
        }
        // Google Analytics  CONTINUED_LOAN_APPLY
        if (localStorage['ga']) {
          window.gtag('event', 'CONTINUED_LOAN_APPLY')
        }
      } catch(e) {
        console.log(e)
      }
      await this.$axios({
        method: "post",
        url: step,
        data: {
          deviceId: 'H5',
          phone: this.user.phone,
          idcard: this.user.idcard,
          gps: await getLocation(),
          currentStep: 1,
          totalSteps: 7,
          continuedLoan: 'Y'
        }
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              name: e.body.name,
              applyId: e.body.applyId
            })
            this.$router.push('/step/confirm')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      branch.logEvent('11_CONTINUED_LOAN_APPLY', { metadata: this.user.phone })
    },
    // 取消再贷
    async cancelApply() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.$axios({
        method: "post",
        url: applyByCancel,
        data: {
          deviceId: 'H5',
          phone: this.user.phone,
          idcard: this.user.idcard,
          gps: await getLocation(),
        },
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              ...e.body.session.user,
              applyId: e.body.applyId
            })
            this.$router.push('/apply/confirm')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
    },
    onServiceAgreement() {
      if (this.brand.channelInfo) {
        window.open(this.brand.channelInfo.privacyUrl)
      }
    },
    async getUser() {
      await this.$axios({
        method: "get",
        url: getUserInfo,
      }).then((e) => {
        if (e.status.code === "000") {
          this.SET_USER(e.body);
        }
      }).catch(() => {})
    },
    async getQueryStatus() {
      await this.$axios({
        method: "get",
        url: queryStatus,
      }).then((e) => {
        if (e.status.code == "000") {
          this.SET_QUERY_STATUS(e.body);
        }
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.home {
  .titieIcon {
    color: #650188;
    font-size: 26px;
  }
  /deep/ .mint-header-title {
    font-size: 26px;
    font-weight: bold;
    color: #650188;
    text-shadow: 0px 3px 3px rgba(34, 35, 40, 0.22);
  }
  .loan {
    margin: 20px;
    box-shadow: 0px 0px 10px 0px rgba(34, 35, 40, 0.22);
    border-radius: 5px 45px 5px 5px;
    .box {
      position: relative;
      overflow: hidden;
      .val {
        position: absolute;
        width: 100%;
        height: 100%;
        .title {
          font-weight: bold;
          color: #FFFFFF;
          font-size: 15px;
          text-align: center;
          margin-top: 25px;
        }
        .tips {
          margin-top: 8px;
          font-size: 30px;
          text-align: center;
          font-weight: bold;
          color: white;
          .tips_date{
            margin-top: 10px;
            font-size: 16px;
          }
        }
      }
    }
    .bg {
      width: 100%;
      float: left;
    }
    .bottom {
      padding: 10px 50px 0px 50px;
      .mint-button {
        color: #fff;
        height: 33px;
        font-size: 13px;
        background-color: #ffd800;
        border-radius: 33px;
      }
    }
    .link {
      padding: 10px 0;
      text-align: center;
      font-size: 14px;
      color: black;
      padding: 10px 0;
      span {
        color: black;
        text-decoration: underline;
      }
    }
  }
  .not_settle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 25px 20px 25px;
    background: white;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0px 0px 10px 0px rgba(34, 35, 40, 0.22);
    img {
      width: 100px;
      margin: 15px;
    }
    .val {
      padding: 10px;
      flex: 1;
      color: #820ECA;
      div {
        margin-bottom: 10px;
      }
      div > span {
        color: red;
        font-weight: 700;
      }
      .more {
        color: white;
        background: #820ECA;
        padding: 5px 10px;
        border-radius: 10px;
      }
    }
  }
  .flag {
    position: relative;
    margin: 40px 20px 20px 20px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px 0px rgba(34, 35, 40, 0.22);
    img {
      position: absolute;
      width: 85px;
      bottom: 0px;
      left: 0px;
    }
    .val {
      padding: 10px 0px;
      margin-left: 90px;
      flex: 1;
      font-size: 20px;
      color: #820ECA;
      font-weight: 700;
      div > span {
        color: #FFD801;
      }
      > span {
        color: #869096;
        font-size: 13px;
      }
    }
    .go {
      width: 50px;
      height: 50px;
      background-color: #820ECA;
      border-radius: 50px;
      line-height: 50px;
      text-align: center;
      color: white;
      font-weight: 700;
      margin-right: 10px;
    }
  }
  .userRecomendation {
    padding: 10px 25px 20px 25px;
    font-size: 15px;
    font-weight: bold;
  }
  .inviteFriends {
    margin: 0px 20px 20px 20px;
    margin-right: 80px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d9b6ef;
    .val {
      flex: 1;
      text-align: center;
      span {
        font-size: 20px;
        font-weight: bold;
        color: #820ECA;
      }
      .btn {
        margin-top: 10px;
        padding: 5px 20px;
        background-color: #820ECA;
        border-radius: 4px;
        color: white;
        font-size: 12px;
        font-weight: bold;
        display: inline-block;
      }
    }
    img {
      width: 90px;
      margin: 5px 15px 5px 5px;
    }
  }
  .box {
    .step {
      &:nth-child(even) {
        background: #eff6f8;
      }
      & > .title {
        font-size: 18px;
        padding: 10px;
        text-align: center;
        color: #820ECA;
      }
      .item {
        padding: 15px 20px 15px 10px;
        display: flex;
        align-items: center;
        .icon {
          font-size: 30px;
          margin: 0px 10px;
        }
        .index {
          width: 23px;
          flex-shrink: 0;
          height: 23px;
          text-align: center;
          line-height: 23px;
          background: #820ECA;
          color: #FFFFFF;
          font-weight: bold;
          border-radius: 50%;
        }
        .des {
          padding: 0 10px;
          flex-grow: 1;
          div {
            &:last-child {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
