import moment from 'moment';
import Vue from 'vue'
import { behavior } from '../../utils/api'

export default {
  namespaced: true,
  state: () => ({
    data: {},
    startTime: '',
    records: [],
  }),
  getters: {
    applyId: (state, getters, rootState) => {
      return rootState.applyId
    }
  },
  mutations: {
    BEHAVIOR_ADD(state, val) {
      console.log(val)
      let id = val.id, newValue = val.newValue, oldValue = state.data[id], startTime = state.startTime
      state.data[id] = newValue;
      let endTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
      state.records.push({ id, endTime, newValue, oldValue, startTime, });
      state.startTime = endTime;
    },
    
  },
  actions: {
    BEHAVIOR_SEND({ state, getters }) {
      Vue.axios({
        method: "post",
        url: behavior,
        data: {
          applyId: getters.applyId,
          outerIp: '',
          internalIp: '',
          screenWidth: window.screen.width,
          screenHeight: window.screen.height,
          records: state.records
        }
      })
      state.records = []
    },
  }
}