export default {
  welcome: "Welcome",
  projectName: "AjeLoan",
  login: "Login",
  register: "Registration",
  phone: "Phone",
  mine: "My",
  logOut: "Log out",
  loginTips1: "Click to use SMS verification code for quick login",
  loginTips2: "Click to log in quickly with your password",
  upTo: "UP TO",
  loanInfo: "Loan arrived so fast ! !",
  userRepayInfo: "How much should I pay back?",
  userRenewalInfo: "How much did I borrow last time?",
  userBorrowInfo: "How much can I borrow?",
  firstName: "First name",
  firstNamePlaceholder: "Please enter a first name",
  middleName: "Middle name(Optional)",
  middleNamePlaceholder: "Please enter a middle name",
  lastName: "Last name",
  lastNamePlaceholder: "Please enter a last name",
  backupPhone: "Backup phone(Optional)",
  backupPhonePlaceholder: "Please enter a backup phone",
  whatsApp: "whatsApp",
  whatsAppPlaceholder: "Please enter a whatsApp",
  whatsAppError: "whatsApp Cannot be empty",
  facebook: "Facebook(Optional)",
  facebookPlaceholder: "Please enter a facebook",
  viewMore: "View more",
  userRecomendation: "User feedback",
  personalInfo: "Personal information",
  workInfo: "Job Information",
  jobauth: "Job Certification",
  wontactInfo: "Contact information",
  relationshipauth: "Contact person certification",
  idcardInfo: "ID card information",
  idcardauth: "Identification",
  confirmOrder: "Confirmation of orders",
  bankCardInfo: "Bank Card Information",
  birthday: "Birthday",
  birthdayError: "Birthday cannot be empty",
  birthdayPlaceholder: "Please select your birthday",
  email: "Email",
  emailPlaceholder: "Please enter your email address",
  emailError: "Mailbox cannot be empty",
  gender: "Gender",
  genderError: "Please select a gender",
  repaymentDate: "Repayment Date",
  withdrawalAmount: "Withdrawable cash amount",
  repayableAmount: "Amount due",
  loanDetail: "Order details",
  copy: "Copy",
  year: "Year",
  month: "Month",
  date: "Day",
  copySuccess: "Copy successfully!",
  invitationAddress: "Exclusive invitation address",
  inviteFriendsStep: "Get your reward in three easy steps",
  inviteFriendsSteptips1: "Invite your friends to register",
  inviteFriendsSteptips2: "Best friend loan success",
  inviteFriendsSteptips3: "Receive cash vouchers",
  activityRules: "Activity Rules",
  toBeExchanged: "To be exchanged",
  exchanged: "Redeemed",
  expired: "Expired",
  validUntil: "Valid until",
  exchangeImmediately: "Redeem Now",
  couponPopupTitle: "Warm Tips",
  couponPopupTips: "Sure to redeem your voucher now?",
  activityRulesList: [
    "1.This event is open to both new and existing customers.",
    "2.Limited prizes are available on a first-come, first-served basis while stocks last.",
    "3.You will be rewarded for successfully inviting a friend. (You will only be rewarded after your friend has completed the loan).",
    "4.The reward amount will be paid directly to your account.",
    '5.Reward earnings can be viewed under "Coupons".',
    "6.The final decision on this event rests with the company.",
  ],
  male: "Male",
  female: "Female",
  degree: "Education",
  degreePlaceholder: "Please select your education",
  degreeError: "Education cannot be empty",
  marriage: "Marital status",
  marriagePlaceholder: "Please select marital status",
  marriageError: "Marital status cannot be empty",
  religion: "Religion",
  religionPlaceholder: "Please select a religion",
  religionError: "Religion cannot be empty",
  addressSelect1: "State",
  addressSelect2: "City",
  addressPlaceholder: "Please select an address",
  companyAddressSelectPlaceholder: "Please select a company address",
  confirmBankCardInfo: "Confirm bank card details",
  addressError: "Residence address cannot be empty",
  houseNo: "House number",
  houseNoPlaceholder: "Please enter your house number",
  houseNoError: "Please enter your house number",
  companyAddress: "Company Address",
  companyAddressPlaceholder: "Please enter your company address",
  companyAddressError: "Address cannot be empty",
  residentialAddress: "Address of residence",
  salaryType: "Salary type",
  salaryTypePlaceholder: "Please select salary type",
  salaryTypeError: "Please select the type of salary",
  companyName: "Company name",
  payDate: "Payroll Period",
  payDatePlaceholder: "Please select a payroll period",
  payDateError: "Please select a payroll period",
  payDateTips: "Example:25 if you’re paid on the 25th",
  companyNamePlaceholder: "Please enter your company name",
  companyNameError: "Company name cannot be empty",
  averageMonthlyIncome: "Average monthly income",
  averageMonthlyIncomePlaceholder: "Please select average monthly income",
  averageMonthlyIncomeError: "Average monthly income cannot be empty",
  cashRedPacket: "Cash bonus",
  invitePrize: "Invite to win",
  inviteFriendsImmediately: "Invite a friend now",
  noInviteFriends: "No friends invited yet",
  myPromotionTips: "Become a new user time:",
  workPhone: "Work Phone(Optional)",
  bankInfo: "Bank Cards",
  bankInfoTips: "Tip: Collections and repayments may be subject to banking",
  bankInfoPopupTips:
    "If account details are not filled in correctly, you will not be able to obtain a loan.",
  workPhonePlaceholder: "Please enter your work telephone number",
  workPhoneError: "Work phone number cannot be empty",
  workPhoneWarning: "Please enter the correct work telephone number",
  relativeContact: "Relative contact",
  otherContact: "Other contacts",
  relationship: "Relationship",
  relationshipPlaceholder: "Please select the contact relationship",
  relationshipError: "Relationship cannot be empty",
  orderHistory: "Order history",
  repaymentInfo: "Repayment information",
  repayment: "Repayment",
  remainingAmountDue: "Remaining amount due",
  repaymentTips: "There is one unpaid bill.",
  repaymentMessage:
    "We suggest that you repay the loan immediately on this page, so as not to affect your next loan application.",
  repaymentMessage2:
    "We need a few minutes to process your loan repayment application，please wait patiently. If you cannot receive the Validate code, please resend the verification code. Thanks for your cooperation.",
  inviteFriends: "Invite a friend",
  discountCoupon: "Coupons",
  myPromotion: "My Promotions",
  phonePlaceholder: "Please enter your mobile phone number",
  phoneWarning: "Please enter the correct mobile phone number",
  phoneError: "Mobile phone number cannot be empty",
  password: "Password",
  passwordPlaceholder: "Please enter your password",
  passwordWarning: "Wrong password format",
  passwordError: "Password cannot be empty",
  forgetPassword: "Forgot your password?",
  validateCode: "Validate code",
  smsCode: "Validate code",
  youCanTry: "You can also try",
  getIvrCode: "Voice Validate code",
  confirmPassword: "Confirm password",
  validateCodeWarning: "Captcha format error",
  validateCodeError: "Captcha cannot be empty",
  comfirmPasswordWarning: "Password not entered twice",
  comfirmPasswordError: "Please enter your password",
  getSmsTips: "Validate code may take a long time, please be patient",
  identifyCardTips: "Photo should be clear and complete",
  holdTheIdentifyCardTips:
    "I.e Take a picture holding your card. Fully exposed NIK card information Avioid exposed ID overlaps width the NIK card clear.",
  employeeIdCardTips:
    "Upload your Emploe ID card to get more borowing money and loan faster.",
  loanTips:
    "Maintaining a good repayment behavior will help you to increase your loan amount. 96% of users loan amount haveincreased subsequently.",
  orderConfirmation: "Order confirmation:",
  fee: "Fee",
  amountPayable: "Amount payable",
  repaymentReliefReward: "Repayment relief reward",
  repaymentAmount: "Repayment loan amount",
  discountTitle: "Coupons",
  discountTips: "You have a voucher to use",
  overdue: "Expired",
  useImmediately: "Use Now",
  stage: "Stage",
  free: "Free",
  clickingAgreeOur: "By clicking Submit,you agree to our",
  loanAgreement: "Loan agreement",
  clickModifyBankCard: "Click here to modify bank card",
  modifyBankInfo: "Modify your bank card",
  oldBankAccount: "Old Bank Account",
  newBankAccount: "New Bank Account",
  getCodeTitle: "Please typ the Validate code sent to",
  codeInfo:
    "Cant't receive the Validate code?You can also check your Mobile phone signal or try the following ways.",
  getIvrHint: "Receive code by call",
  getCodeHint: "Re-acquire Validate code",
  deductionAuth: "ATM Bank Card",
  cardVerificationStatus: "Card Verification Status",
  deductionAuthTips:
    "Please verify your bank card to get more credit.In this process,system will deduct your 50 NR for verification.It will be return to your when repay loan.",
  notVerified: "NOT VERIFIED",
  verified: "VERIFIED",
  validateCard: "Validate Card",
  stepBack: "Step back",
  applicationRepayment: "This application for repayment",
  confirm: "Confirm",
  cancel: "Cancel",
  idcard: "BVN",
  idcardPlaceholder: "Please enter BVN",
  idcardError: "BVN Cannot be empty",
  confirmModification: "Confirmation of changes",
  modifyImmediately: "Modify Now",
  modify: "Modify",
  submit: "Submit",
  verifyMarkTips: "Name and bank account number do not match",
  waitTips1: "Loan information (approval in progress))",
  waitTips2: "Refresh screen to check loan status",
  supplementaryTips1: "Application failed",
  supplementaryTips2: "Your identity information is incorrect",
  supplementaryTips3:
    "Please complete the application within one working day, otherwise the application will be cancelled!",
  passedTips1: "Application approved (in the process of disbursement))",
  rejectedTips1: "Application failed",
  rejectedTips2: "This application was not approved",
  loanDate: "Borrowing date",
  loanAmount: "Amount borrowed",
  loanTerm: "Borrowing period",
  repaymentPeriod: "Repayment period",
  applicationStatus: "Application status",
  moneyMaxTips: "Your current maximum loan amount",
  daysMaxTips: "Your current maximum borrowing period",
  filingDate: "Date of submission",
  paymentDate: "Date of payment",
  serviceFee: "Service Fee",
  amountOfRepayment: "Amount of repayment",
  day: "day",
  waitMsg1:
    "Your order your loan application is being reviewed, staff will contact you within 1-2 working days, please keep your phone open!",
  passedMsg1:
    "*Your loan application has been approved and is being released.;",
  passedMsg2:
    "If you have not received your payment, please check with your bank first, or contact customer service for any other questions.",
  reapply: "Re-apply",
  back: "Back",
  nextStep: "Next step",
  applyImmediately: "Apply Now",
  clickApplyAgree: "By clicking apply you agree to",
  serviceAgreement: "Service Agreement",
  telephone: "Customer Service Number",
  supplementaryInformation: "Additional Information",
  supplementaryImage: "Additional Documents",
  supplementaryBankCard: "Additional bank card",
  prompt: "Tips",
  idcardExample: "Example of photo upload",
  identifyCard: "Identify card(optional)",
  holdTheIdentifyCard: "Hold the identify card(optional)",
  employeeIdCard: "Employee ID card(optional)",
  voterCard: "Voter card(optional)",
  driverLicense: "Driver license(optional)",
  passport: "Passport(optional)",
  taxCard: "Tax card(optional)",
  name: "Name",
  namePlaceholder: "Please enter your name",
  nameError: "Name cannot be empty",
  bankAccountHolder: "Bank card holder",
  atmBankCardNo: "atm bank card no",
  atmBankCardNoPlaceholder: "Please enter atm bank card no",
  atmBankCardNoError: "Please enter atm bank card no",
  bankCardNumber: "Bank card number",
  bankCardNumberPlaceholder: "Please enter your bank card number",
  bankCardNumberError: "Bank card number cannot be empty",
  bankAccountNumber: "Bank account number",
  bankAccountNumberPlaceholder: "Please enter your bank account number",
  bankAccountNumberError: "Bank account number cannot be empty",
  bank: "Bank",
  bankPlaceholder: "Please select a bank",
  bankError: "Bank cannot be empty",
  bankCard: "Bank Card",
  contractNo: "Contract number",
  amount: "Amount",
  amountPlaceholder: "Please enter the amount",
  notHaveLoan: "You do not have a loan currently,please get a loan first.",
  getMoneyNow: "Get money now",
  selectPhoneTips: "Please select a mobile phone number you have used",
  applicationDate: "Application date",
  loanLimit: "Repayment period",
  contractStatus: "Contract Status",
  idcardExampleList: [
    "Standard",
    "Missing border",
    "Blurred focus",
    "Photocopies photographed",
  ],
  pleaseUploadHandHeldIdPhoto: "Please upload a hand-held ID photo",
  homeStep1: {
    title: "Simple steps to obtaining and paying your loan",
    list: [
      {
        title: "Easy to use",
        value: "5 minutes to enter information",
      },
      {
        title: "Quick review",
        value: "2 hours for quick review",
      },
      {
        title: "Get funded",
        value: "24 hours to fund",
      },
    ],
  },
  homeStep2: {
    title: "Apply for a loan fast",
    list: [
      {
        title: "Region",
        value: "National",
      },
      {
        title: "Age",
        value: "22-45 years",
      },
      {
        title: "Occupation",
        value: "Remain stable",
      },
    ],
  },
  homeStep3: {
    title: "Reasons for using this service",
    list: [
      {
        title: "Easy Payment",
        value: "You can make repeat partial payments and pay off your loan within 90 days. Just make sure you pay your monthly renewal fees on time.",
      },
      {
        title: "Easy",
        value: "All you need to do is register online",
      },
      {
        title: "Fast release",
        value: "From review to disbursement in just 4 hours",
      },
      {
        title: "Convenience",
        value: "All you need to do is sign up for an online loan application, no complicated and cumbersome process",
      },
    ],
  },
  loanLengthTips: "The number of your current outstanding loans",
  loanRecord: "Loan record",
  interestFate: "Daily Interest Rate",
  interest: "Interest",
  byPaymentCompany: 'By payment company',
  byBank: 'By bank',
  accountName: 'Account name',
  bankName: 'Bank Name',
  bankAccount: 'Bank account',
  repaymentRemark: 'Repayment remark',
};
