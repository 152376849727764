<template>
  <img src="../assets/hotline.png" class="icon" @click="onCall">
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(['brand'])
  },
  methods: {
    onCall() {
      if (this.brand.serviceInfo) {
        window.location.href = `tel://${this.brand.serviceInfo.ccphone}`;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.icon {
  width: 25px;
  height: 25px;
}
</style>
